import React, { useRef, useState, useEffect } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import axios from "axios";
import moment from "moment"
import InputMask from 'react-input-mask';
import { connect } from "react-redux";
import { setProject, setReportDate, getProjects, getProjectsByUser } from "../../actions/index";
import { withTranslation } from 'react-i18next';

// reactstrap components
import {
  Button,

  Input,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import { Dock } from "react-dock"
import classnames from "classnames";
import {userCan, userCant} from "../../services/Authorization/authService";

const AddMaterialsTable = ({ 
  t, 
  user, 
  projectId, 
  materialTableData = [], 
  getSomeMaterials = () => {}, 
  refreshMaterial = () => {} 
}) => {
  const cancelTokenRef = useRef(null);
  
  const [state, setState] = useState({
    materialToAddValue: '',
    editMaterial: null,
    showForm: false,
    errors: {},
    isLoading: false,
    materials: []
  });

  const fetchMaterials = async () => {
    if (!projectId) {
      console.log('No projectId provided');
      return;
    }

    setState(prev => ({ ...prev, isLoading: true }));

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/material/project/${projectId}`,
        {
          headers: { 'Authorization': localStorage.jwtToken }
        }
      );

      const materials = Array.isArray(response.data) ? response.data : [];
      
      setState(prev => ({
        ...prev,
        materials,
        isLoading: false
      }));

      if (typeof getSomeMaterials === 'function') {
        getSomeMaterials(materials);
      }
    } catch (error) {
      console.error('Fetch error:', error);
      setState(prev => ({
        ...prev,
        materials: [],
        isLoading: false,
        errors: { general: `Failed to fetch materials for project ${projectId}` }
      }));
    }
  };

  const deleteMaterialByMaterialId = async (materialId) => {
    if (userCant(user, 'can_manage_tasks_and_materials')) return;

    setState(prev => ({ ...prev, isLoading: true }));

    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/material/${materialId}`,
        {
          headers: { 'Authorization': localStorage.jwtToken }
        }
      );
      await fetchMaterials();
    } catch (error) {
      console.error('Delete error:', error);
      setState(prev => ({
        ...prev,
        isLoading: false,
        errors: { general: 'Failed to delete material' }
      }));
    }
  };

  const createNewMaterial = async (element) => {
    setState(prev => ({ ...prev, errors: {} }));

    if (state.editMaterial) {
      return saveRowData();
    }

    setState(prev => ({ ...prev, isLoading: true }));

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/material/`,
        {
          createdDate: moment(new Date()).format(),
          materialName: element,
          projectId
        },
        {
          headers: { 'Authorization': localStorage.jwtToken }
        }
      );

      setState(prev => ({
        ...prev,
        showForm: false,
        materialToAddValue: '',
        errors: {},
        isLoading: false
      }));

      await fetchMaterials();
    } catch (error) {
      console.error('Create error:', error);
      setState(prev => ({
        ...prev,
        isLoading: false,
        errors: error.response?.data || { general: 'Failed to create material' }
      }));
    }
  };

  const saveRowData = async () => {
    setState(prev => ({ ...prev, isLoading: true }));

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/material/`,
        {
          _id: state.editMaterial._id,
          materialName: state.materialToAddValue,
        },
        {
          headers: { 'Authorization': localStorage.jwtToken }
        }
      );

      setState(prev => ({
        ...prev,
        editMaterial: null,
        showForm: false,
        materialToAddValue: '',
        errors: {},
        isLoading: false
      }));

      if (typeof refreshMaterial === 'function') {
        refreshMaterial(response.data);
      }

      await fetchMaterials();
    } catch (error) {
      console.error('Edit error:', error);
      setState(prev => ({
        ...prev,
        isLoading: false,
        errors: error.response?.data || { general: 'Failed to edit material' }
      }));
    }
  };

  useEffect(() => {
    console.log('ProjectId changed:', projectId);
    fetchMaterials();
  }, [projectId]);

  const handleMaterialToAddText = (e) => {
    setState(prev => ({ ...prev, materialToAddValue: e.target.value }));
  };

  const componentWillReceiveProps = ({ timesheetLogs, dateSelected, materialTableData }) => {
    setState(prev => ({ ...prev, timesheetLogs, dateSelected, materialTableData }));
  };

  const handleInputChange = (cellInfo, event) => {
    let data = [...state.materials];
    data[cellInfo.index][cellInfo.column.id] = event.target.value;

    setState(prev => ({ ...prev, materials: data }));
  };

  const toggleEditable = (material) => {
    console.log('Editing material:', material); // Debug log
    setState(prev => ({
      ...prev,
      showForm: true,
      editMaterial: material,
      errors: {},
      materialToAddValue: material.materialName // Make sure we're using the correct property name
    }));
  };

  const renderEditable = (cellInfo) => {
    return (
      <div>
        {cellInfo.value}
      </div>
    );
  };

  const renderActiveButtons = (row) => {
    if (userCant(user, 'can_manage_tasks_and_materials')) {
      return null;
    }

    return (
      <div>
        <Button
          onClick={() => toggleEditable(row.original)}
          size="xs"
          className="btn-icon btn-neutral btn btn-info"
        >
          <i className="fa fa-edit" />
        </Button>
        <Button
          color="danger"
          size="sm"
          className="btn-icon btn-neutral btn btn-info"
          onClick={() => deleteMaterialByMaterialId(row.original._id)}
          disabled={state.isLoading}
        >
          <i className="fa fa-remove" />
        </Button>
      </div>
    );
  };

  return (
    <div className="content">
      <Dock
        position='right'
        isVisible={state.showForm}
        size={window.innerWidth < 600 ? 1 : (window.innerWidth < 900 ? 0.5 : 0.3)}
        duration={300}
      >
        {state.showForm && (
          <Row>
            <Col>
              <CardBody>
                <h3>
             
                  {t(`${state.editMaterial ? 'Edit' : 'Add'} Material`)}
                </h3>
                <Row>
                  <Col>
                    <Input 
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': state.errors.materialName
                      })}
                      value={state.materialToAddValue}
                      onChange={(e) => setState(prev => ({
                        ...prev,
                        materialToAddValue: e.target.value
                      }))}
                      placeholder={t('Add material name')}
                      disabled={state.isLoading}
                    />
                    {state.errors.materialName && (
                      <div className="invalid-feedback d-block">
                        {state.errors.materialName}
                      </div>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button 
                      color="info"
                      onClick={() => createNewMaterial(state.materialToAddValue)}
                      disabled={state.isLoading}
                    >
                      {state.isLoading ? (
                        <span>
                          <i className="fa fa-spinner fa-spin" /> {t('Loading...')}
                        </span>
                      ) : (
                        t(`${state.editMaterial ? 'Save ' : 'Add '} Material`)
                      )}
                    </Button>
                    <Button 
                      color="info"
                      onClick={() => setState(prev => ({ ...prev, showForm: false }))}
                    >
                      {t("Cancel")}
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Col>
          </Row>
        )}
      </Dock>

      {userCan(user, 'can_manage_tasks_and_materials') && (
        <Button 
          color="info" 
          onClick={() => setState(prev => ({ ...prev, showForm: !prev.showForm }))}
        >
          {t("Add Material")}
        </Button>
      )}

      <ReactTable
        data={state.materials}
        columns={[
          {
            Header: t("Materials Id"),
            accessor: "_id",
            show: false
          },
          {
            Header: t("Material"),
            accessor: "materialName",
            Cell: renderEditable
          },
          {
            Header: t("Actions"),
            id: "activateToggle",
            width: 150,
            Cell: ((value) => (renderActiveButtons(value)))
          }
        ]}
        defaultPageSize={1}
        pageSize={state.materials.length}
        showPaginationTop={false}
        showPaginationBottom={false}
        className="-striped -highlight primary-pagination"
        loading={state.isLoading}
      />

      {state.errors.general && (
        <div className="text-danger text-center mt-3">
          {state.errors.general}
        </div>
      )}
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    setProject: project => dispatch(setProject(project)),
    getProjects: () => dispatch(getProjects()),
    setReportDate: date => dispatch(setReportDate(date)),
    getProjectsByUser:(userid)=>dispatch(getProjectsByUser(userid))

  };
}

const mapStateToProps = state => ({
  auth: state.auth,
  user: state.auth.user,
  project: state.allOtherReducers.project,
  projectId: state.allOtherReducers.projectId,
  projectData: state.allOtherReducers.projectData,
  reportDate: state.allOtherReducers.reportDate,
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps )(AddMaterialsTable));


