import React from "react";
import _ from "lodash"
import axios from "axios"
import moment from "moment"
import AddTasksTable from "views/tables/AddTasksTable"
import {connect} from "react-redux";
import {getProjects} from "../../../actions/index";
import {Dock} from "react-dock"
import {withTranslation} from 'react-i18next';

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    FormGroup,
    Button
} from "reactstrap";
import {userCant, userCan} from "../../../services/Authorization/authService.js";


function mapStateToProps(state) {
    return {
        project: state.allOtherReducers.project,
        projectId: state.allOtherReducers.projectId,
        projectData: state.allOtherReducers.projectData,
        reportDate: state.allOtherReducers.reportDate,
        user: state.auth.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getProjects: () => dispatch(getProjects()),
        setScheduleItem: scheduleItem => dispatch(setScheduleItem(scheduleItem))
    };
}


class ConnectedTasks extends React.Component {
    constructor(props) {
        super(props);
        this.getSomeTasks = this.getSomeTasks.bind(this)
        this.isDockVisibleControl = this.isDockVisibleControl.bind(this)
        this.isUpdateDockVisibleControl = this.isUpdateDockVisibleControl.bind(this)
        this.refreshTasks = this.refreshTasks.bind(this)
        this.state = {
            resetState: false,
            taskData: [],
            project: "",
            projectId: "",
            isDockVisible: false,
            isUpdateDockVisible: false,
        };
    }

    componentWillMount() {
        if (
            userCant(this.props.user, 'tasks.index') &&
            userCant(this.props.user, 'tasks.manage')) {
            return this.props.history.push('/admin/instructions')
        }
    }

    componentDidMount() {

        this.getSomeTasks();

    }


    componentDidUpdate(prevProps) {

        if (this.props.projectId !== prevProps.projectId) {
            this.getSomeTasks();
        } else if (_.isEmpty(this.props.projectData[0])) {
        }

        if (this.props.reportDate !== prevProps.reportDate) {
            this.getSomeTasks();
        }

    }

    getSomeTasks() {
        if (userCant(this.props.user, 'tasks.manage')) {
            return
        }

        axios.get(process.env.REACT_APP_API_URL + "/api/task/project/" + this.props.projectId, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        })
            .then(res => {
                this.setState({
                    taskData: res.data
                }, () => console.log(this.state.taskData));
                return res.data
            }).catch((error) => {
            ;
        })
    }

    isDockVisibleControl() {
        this.setState({isDockVisible: !this.state.isDockVisible}, () => {
        })
    }

    isUpdateDockVisibleControl(row_id, tableObject) {
        let _contractedWorkRowData = [];

        if (tableObject !== undefined) {
            _contractedWorkRowData = tableObject.filter(obj => `${obj._id}` === `${row_id}`)
        }

        this.setState({
            contractedWorkRowData: _contractedWorkRowData,
            isUpdateDockVisible: !this.state.isUpdateDockVisible
        })
    }

    refreshTasks(task) {
        let tasksData = this.state.taskData.map(row => {
            return row._id === task._id ? task : row
        })

        this.setState({
            taskData: tasksData
        })
    }

    render() {

        if (
            userCant(this.props.user, 'tasks.index') &&
            userCant(this.props.user, 'tasks.manage')) {
            return this.props.history.push('/admin/instructions')
        }

        return (
            <>
                <div className="content">
                    {userCan(this.props.user, 'tasks.manage') && 
                    <Row>
                        <Col md="6">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">{this.props.t("Tasks")}</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <AddTasksTable
                                        tasktableData={this.state.taskData}
                                        getSomeMaterials={this.getSomeTasks}
                                        refreshMaterial={this.refreshTasks}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>}
                </div>
            </>
        );
    }
}

const Tasks = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ConnectedTasks));

export default Tasks;
