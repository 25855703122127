import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';


const PrivateRoute = ({component: Component, auth, prop, ...rest}) => {
    return <Route
        {...rest}
        render={props => renderCheck(props, prop, auth, Component,rest)}
    />
}

const renderCheck = (props, componentData, auth, Component,rest) => {

    let hasEmployeeRole = auth.user.roles.find(r => r.name === 'employee')

    const customFieldsEnabled = auth.client?.config?.enableCustomFields

    if (customFieldsEnabled) {
        if (hasEmployeeRole && componentData.path !== '/complete-user-registration' && !auth.user.registrationCompleted) {
            return <Redirect to="/app/complete-user-registration"/>
        }
    }

    if (!componentData.dontRequireProject && !rest.projectId) {
        return <Redirect to="/app/initialisation"/>
    }

    if (auth.isAuthenticated === true) {
        return <Component {...props} />
    }

    return <Redirect to="/auth/login"/>
}

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  projectId: state.allOtherReducers.projectId
});

export default connect(mapStateToProps)(PrivateRoute);
